<!--设备列表-->
<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('device.device/add')" addName="新增设备" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.device_no" size="small" clearable placeholder="按设备编号查询" class="from-item-width"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.device_num" size="small" clearable placeholder="按通讯编号查询" class="from-item-width"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.flow_card" size="small" clearable placeholder="按流量卡号查询" class="from-item-width"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.type_id" size="small" filterable reserve-keyword clearable class="from-item-width" placeholder="按设备类型查询" :remote-method="remoteType"
						   :loading="typeLoading">
					<el-option v-for="item in typeList" :key="item.type_id" :label="item.type_name" :value="item.type_id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item" v-if="!$store.state.user.userInfo.group_id || $store.state.user.userInfo.group_id==0">
				<el-select v-model="searchFormData.group_id" size="small" filterable reserve-keyword clearable class="from-item-width" placeholder="按所属小区查询" :remote-method="remoteGroup"
					:loading="groupLoading">
					<el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name" :value="item.group_id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item" v-if="!$store.state.user.userInfo.group_id || $store.state.user.userInfo.group_id==0">
				<el-select v-model="searchFormData.agent_id" placeholder="按所属代理查询" clearable size="small" class="from-item-width">
					<el-option v-for="item in agentList" :key="item.agent_id" :label="item.agent_name" :value="item.agent_id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.device_status" placeholder="按设备状态查询" clearable size="small" class="from-item-width">
					<el-option label="在线" :value="20">
					</el-option>
					<el-option label="离线" :value="30">
					</el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="device_no" title="设备编号" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="device_num" title="通讯编号" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="flow_card" title="流量卡号" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="type_name" title="设备类型" align="center" min-width="100px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="group_name" title="所属小区" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="agent_name" v-if="$hasAccess('report.group/viewb')" title="所属代理" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{row.agent_name?row.agent_name:'自营'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="fee_type" title="联网模式" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{types.fee_type[row.fee_type]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="update_time" title="更新时间" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="device_status" title="使用情况" align="center" min-width="200px">
				<template v-slot="{ row }">
					<div class="sd_use">使用率：{{row.total_num==0?0:(row.use_num/row.total_num*100).toFixed(2)}}%</div>
					<div class="sd_use">
						<span class="sd_spare">扫码{{row.wx_use_num}}个</span>
						<span class="sd_spare2">刷卡{{row.card_use_num}}个</span>
						<span class="sd_error">异常{{row.error_num}}个</span>
						<span class="sd_error">离线{{row.online_num}}{{row.device_type==50?"组":"个"}}</span>
						<span class="sd_spare">测试{{row.test_num}}{{row.device_type==50?"组":"个"}}</span>
						<span class="sd_total">共{{row.total_num}}个</span>
					</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="device_status" title="设备状态" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span :style="{color:types.device_status[row.device_status].color}"> 【{{types.network[row.network]}}】{{types.device_status[row.device_status].name||'未知'}}
					</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="device_status" title="信号强度" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span style="color:red;"> {{row.sig_int}}
					</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" min-width="350">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('device.device/edit')">编辑</el-button>
					<el-button size="small" plain @click="plugDetail(row)">插座</el-button>
<!--					<el-button size="small" plain @click="qrCode(row)">二维码</el-button>-->
					<el-button size="small" plain @click="workOne(row)" v-if="$hasAccess('device.device/log')">日志</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('device.device/delete')">删除</el-button>
					<el-button size="small" plain @click="showPlugStatusLog(row)" v-if="$hasAccess('device.device/plug_status_log')">状态日志</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 二维码 -->
		<el-dialog title="设备二维码" :visible.sync="showQrCode" width="600px" height="650px" center class="qrcode" @closed="closedQrcode">
			<div class="qr-code">
				<div class="qr-code-box">
					<div class="qrcode-content" v-if="qrcode">
						<div class="qrcode-url">
							<img :src="qrcode" />
						</div>
					</div>
					<span slot="footer" class="dialog-footer">
						<el-button size="small" @click="qrcodeDownload('wxcode')">下载小程序码</el-button>
					</span>
				</div>
<!--				<div class="qr-code-box">-->
<!--					<div class="qrcode-content" v-if="linkQrcode">-->
<!--						<div class="qrcode-url">-->
<!--							<img :src="linkQrcode" />-->
<!--						</div>-->
<!--					</div>-->
<!--					<span slot="footer" class="dialog-footer">-->
<!--						<el-button size="small" @click="qrcodeDownload('linkcode')">下载二维码</el-button>-->
<!--					</span>-->
<!--				</div>-->
			</div>
		</el-dialog>
		<!-- 日志 -->
		<el-dialog title="设备交互日志" :visible.sync="workDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" :fullscreen="true" class="logDialog">
			<Tables ref="xWorkTable" tableName="设备日志" :tableData="workTableData" :totalPage="workTotalPage" :isLoading="workIsLoading" :searchFormData="workSearchFormData" :showRefresh="true"
				:showSearch="true" @getList="getWorkList" @clearSearch="clearWorkSearch">
				<el-form-item slot="search-item">
					<el-input v-model="workSearchFormData.plug_num" size="small" clearable placeholder="按插座号查询"></el-input>
				</el-form-item>
				<vxe-table-column slot="table-item" field="create_time" title="时间" width="160">
				</vxe-table-column>
				<vxe-table-column slot="table-item" field="plug_num" title="插座号" width="65"></vxe-table-column>
				<vxe-table-column slot="table-item" field="explain" title="说明" align="left"></vxe-table-column>
				<vxe-table-column slot="table-item" field="content" title="源指令" width="340" align="left"></vxe-table-column>
			</Tables>
		</el-dialog>
		<!-- 枪口状态日志 -->
		<el-dialog title="枪口状态日志" :visible.sync="plugStatusShow" width="560px" height="650px" center >
			<el-select v-model="plugStatusSearch.plug_num" placeholder="枪口号" size="small" style="width:90px" @change="plugStatusLog">
				<el-option v-for="(item,index) in plugStatusDevice.total_num" :label="'@'+item" :value="item" :key="index"></el-option>
			</el-select>
			<el-select v-model="plugStatusSearch.plug_status" placeholder="状态" clearable size="small" style="width:90px;margin-left:3px" @change="plugStatusLog">
				<el-option v-for="(item,ky) in plugStatusText" :label="item" :value="ky" :key="ky"></el-option>
			</el-select>
			<el-date-picker v-model="plugStatusSearch.bdt" @change="plugStatusLog" value-format="yyyy-MM-dd" size="small" style="width:128px;margin-left:3px" clearable placeholder="开始日期"></el-date-picker>
			<el-date-picker v-model="plugStatusSearch.edt" @change="plugStatusLog" value-format="yyyy-MM-dd" size="small" style="width:128px;margin-left:3px" clearable placeholder="结束日期"></el-date-picker>
			<el-button size="small" style="margin-left:3px" @click="plugStatusLogOut">导出</el-button>
			<el-table ref="plugStatusTable" :data="plugStatusData">
				<el-table-column prop="create_time" label="上报时间"></el-table-column>
				<el-table-column prop="plug_status_text" label="状态信息"></el-table-column>
			</el-table>
			<el-pagination style="padding: 10px;display: flex;justify-content: flex-end;" :current-page.sync="plugStatusPage" :page-size="10" layout="prev, pager, next" :total="plugStatusTotal" @current-change="plugStatusLog" @size-change="plugStatusLog">
        	</el-pagination> 
		</el-dialog>
	</div>
</template>
<script>
	import Tables from "@/components/tables";
	import XLSX from "xlsx";

	export default {
		name: "device-device-list",
		components: {
			Tables,
		},
		data() {
			return {
				groupLoading: false,
				typeLoading: false,
				// table信息
				tableName: "设备清单",
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				deviceList: [],
				groupList: [],
				typeList: [],
				agentList: [],
				// 类型信息
				types: {
					device_type: {
						10: "双枪",
						20: "单枪"
					},
					fee_type: {
						10: "互联互通",
						20: "直连",
					},
					fee_model: {
						10: "收费",
						20: "免费",
					},
					power_type: {
						10: "大功率",
						20: "小功率",
					},
					allow_short: {
						10: "允许",
						20: "不允许",
					},
					device_status: {
						20: {
							name: "在线",
							color: "#008E4D",
						},
						30: {
							name: "离线",
							color: "#F56C6C",
						},
						40: {
							name: "正常",
							color: "#008E4D",
							desc: "#67C23A",
						},
					},
					network: {
						0: "未知网络",
						1: "2G",
						2: "4G"
					}
				},
				// 二维码弹窗
				showQrCode: false,
				selDeviceDetail: undefined,
				qrcode: undefined, // 对应小程序码
				linkQrcode: undefined, // 对应关联到小程序码的二维码
				// 日志
				workDialogShow: false,
				workDialogFormData: {},
				workDeviceNum: undefined,
				workIsLoading: false,
				workTableData: [],
				workTotalPage: 0,
				workSearchFormData: {},
				// 设备状态日志
				plugStatusDevice: {total_num:0},
				plugStatusShow: false,
				plugStatusData: [],
				plugStatusSearch: {},
				plugStatusPage: 1,
				plugStatusTotal: 0,
				plugStatusText: {10:"删除", 20:"空闲", 30:"异常", 40:"充电中", 50:"空闲已插枪", 60:"占用（预约锁定）", 70:"故障", 80:"离线"},
			};
		},
		mounted() {
			this.init();
			this.canvasInit();
		},
		activated() {
			this.init();
			this.$refs.xTable.refreshTable();
		},
		methods: {
			// 初始化请求
			init() {
				if (!this.$store.state.user.userInfo.group_id || this.$store.state.user.userInfo.group_id == 0) {
					this.getAgentList();
					this.getGroupList();
					this.remoteType();
				}
			},
			// 获取代理商信息
			async getAgentList() {
				const params = {
					token: this.$store.state.user.token,
					status: 40,
					page: 1,
					size: 100,
				};
				const res = await this.$api.Agent.GetAgentList(params, {});
				this.agentList = res.data;
			},
			// 获取小区信息
			async getGroupList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 1000,
				};
				const res = await this.$api.Device.GetGroupList(params, {});
				this.groupList = res.data;
			},
			// 小区
			async remoteGroup(query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 类型
			async remoteType(query) {
				if (query !== '') {
					this.typeLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetTypeList(params, {
						type_name: query
					})
					this.typeList = res.data
					this.typeLoading = false
				} else {
					this.typeList = []
				}
			},
			// 获取列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize,
				};
				this.isLoading = true;
				const searchData = this.searchFormData;
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id;
				if (!searchData.group_id && group_id && group_id != 0) {
					searchData.group_id = group_id;
				}
				const res = await this.$api.Device.GetDeviceList(params, searchData);
				this.deviceList = res;
				this.tableData = res.data;
				this.totalPage = res.total;
				this.isLoading = false;
				if (group_id == searchData.group_id) {
					searchData.group_id = "";
				}
			},
			// 重置查询
			clearSearch() {
				this.searchFormData = {};
			},
			// 新增设备
			addOne() {
				this.$router.push({
					name: "device-device-add",
				});
			},
			// 编辑设备
			editOne(row) {
				this.$router.push({
					name: "device-device-edit",
					query: {
						device_id: row.device_id,
					},
				});
			},
			// 插座详情
			plugDetail(row) {
				this.$router.push({
					name: "device-device-plugs",
					query: {
						device_id: row.device_id,
					},
				});
			},
			// canvas初始化
			canvasInit() {
				const canvas = document.createElement("canvas");
				canvas.id = "canvas1";
				console.log(canvas);
				canvas.width = 500;
				canvas.height = 510; // 560
				const ctx = canvas.getContext("2d");
				ctx.textAlign = "left";
				ctx.textBaseline = "top";
				ctx.font = "34px Microsoft YaHei";
				this.canvas = canvas;
				this.ctx = ctx;
				const canvas2 = document.createElement("canvas");
				canvas2.id = "canvas2";
				console.log(canvas2);
				canvas2.width = 500;
				canvas2.height = 510; // 560
				const ctx2 = canvas2.getContext("2d");
				ctx2.textAlign = "left";
				ctx2.textBaseline = "top";
				ctx2.font = "34px Microsoft YaHei";
				this.canvas2 = canvas2;
				this.ctx2 = ctx2;
			},
			// 二维码
			qrCode(row) {
				this.selDeviceDetail = row;
				this.showQrCode = true;
				if (!row.app_qrcode || !row.link_qrcode) {
					const params = {
						token: this.$store.state.user.token,
						device_id: row.device_id,
					};
					let that = this;
					this.$api.Device.QrCodeDevice(params).then((res) => {
						if (!res[0] || !res[1]) {
							this.showQrCode = false;
							this.$notify({
								title: "请重试",
								message: "二维码生成失败",
								type: "error",
							});
							this.$refs.xTable.refreshTable();
						} else {
							row.app_qrcode = res[0];
							row.link_qrcode = res[1];
							that.drawQRCode(row);
						}
					});
				} else {
					this.drawQRCode(row);
				}
			},
			drawQRCode(row) {
				let that = this;
				this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
				// 画图
				this.ctx.fillStyle = "#fff";
				this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
				this.ctx.fillStyle = "#008e4d";
				this.ctx.textAlign = "left";
				this.ctx.font = "34px Microsoft YaHei";
				this.ctx.fillText("设备编号：", 35, 20);
				this.ctx.textAlign = "right";
				// this.ctx.font = "40px Microsoft YaHei";
				this.ctx.fillText(row.device_no, 465, 20);
				const image = new Image();
				image.setAttribute("crossOrigin", "anonymous");
				image.src = row.app_qrcode;
				image.onload = () => {
					that.ctx.drawImage(image, 35, 75, 430, 430);
					that.qrcode = that.canvas.toDataURL();
				};
				this.ctx2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
				// 画图
				this.ctx2.fillStyle = "#fff";
				this.ctx2.fillRect(0, 0, this.canvas2.width, this.canvas2.height);
				this.ctx2.fillStyle = "#008e4d";
				this.ctx2.textAlign = "left";
				this.ctx2.font = "34px Microsoft YaHei";
				this.ctx2.fillText("设备编号：", 35, 20);
				this.ctx2.textAlign = "right";
				// this.ctx2.font = "40px Microsoft YaHei";
				this.ctx2.fillText(row.device_no, 465, 20);
				const image2 = new Image();
				image2.setAttribute("crossOrigin", "anonymous");
				image2.src = row.link_qrcode;
				image2.onload = () => {
					that.ctx2.drawImage(image2, 15, 50, 470, 535);
					that.linkQrcode = that.canvas2.toDataURL();
				};
			},
			// 下载二维码
			qrcodeDownload(type) {
				if (type == "wxcode") {
					const aDom = document.createElement("a");
					const event = new MouseEvent("click");
					aDom.download = "设备小程序码_" + this.selDeviceDetail.device_no;
					aDom.href = this.qrcode;
					aDom.target = "_blank";
					aDom.dispatchEvent(event);
				} else if (type == "linkcode") {
					const aDom = document.createElement("a");
					const event = new MouseEvent("click");
					aDom.download = "设备二维码_" + this.selDeviceDetail.device_no;
					aDom.href = this.linkQrcode;
					aDom.target = "_blank";
					aDom.dispatchEvent(event);
				}
			},
			// 关闭二维码
			closedQrcode() {
				this.qrcode = undefined;
				this.linkQrcode = undefined;
			},
			// 删除设备
			deleteOne(row) {
				this.$confirm("确定要删除该设备吗", "提示", {
					cancelButtonClass: "btn-custom-cancel",
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						device_id: row.device_id,
					};
					await this.$api.Device.DeleteDevice(params);
					this.$notify({
						title: "成功",
						message: "删除成功",
						type: "success",
					});
					this.$refs.xTable.refreshTable();
				});
			},
			// 日志
			async workOne(row) {
				if (row.qk_type==10){
					this.workDeviceNum = row.device_num;
				}else {
					this.workDeviceNum = row.device_no;
				}
				// this.workDeviceNum = row.device_num;
				this.clearSearch();
				this.workDialogShow = true;
				if (this.$refs.xWorkTable) {
					this.$refs.xWorkTable.resetForm();
				}
			},
			// 获取日志列表
			async getWorkList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					device_num: this.workDeviceNum,
					page: currentPage,
					size: pageSize,
				};
				this.workIsLoading = true;
				const searchData = this.workSearchFormData;
				const res = await this.$api.Device.GetWorkOrder(params, searchData);
				this.workTableData = res.data;
				this.workTotalPage = res.total;
				this.workIsLoading = false;
			},
			// 重置日志查询
			clearWorkSearch() {
				this.workSearchFormData = {};
			},
			showPlugStatusLog(row){ //线上枪口日志窗口
				this.plugStatusPage = 1;
				this.plugStatusShow = true;
				this.plugStatusDevice = row;
				let dt = new Date();
				dt.setDate(dt.getDate() - 7);
				this.plugStatusSearch = {
					bdt:dt.getFullYear() + "-" + (dt.getMonth()<9?'0'+(dt.getMonth()+1):dt.getMonth()+1) + "-" + (dt.getDate()<10?'0'+dt.getDate():dt.getDate()),
					device_id:row.device_id,
				};
				this.plugStatusTotal = 0;
				this.plugStatusData = [];
				if(this.plugStatusDevice.total_num > 0){
					this.plugStatusSearch.plug_num = 1;
					this.plugStatusLog();
				}
			},
			async plugStatusLog(){ //枪口日志
				const res = await this.$api.Device.plugStatusLog(Object.assign({page:this.plugStatusPage}, this.plugStatusSearch));
				this.plugStatusData = res.data;
				for(let v of this.plugStatusData){
					v.plug_status_text = this.plugStatusText[v.plug_status];
				}
				this.plugStatusTotal = res.total;
			},
			async plugStatusLogOut(){ //枪口日志导出
				const res = await this.$api.Device.plugStatusLog(Object.assign({page:1, size:1000}, this.plugStatusSearch));
				let vals = [["上报时间", "状态信息"]];
				for(let v of res.data){
					vals.push([v.create_time, this.plugStatusText[v.plug_status]]);
				}
				let workbook = XLSX.utils.book_new();
				let sheet = XLSX.utils.aoa_to_sheet(vals);
				XLSX.utils.book_append_sheet(workbook, sheet);
				XLSX.writeFile(workbook, "out.xlsx");  
			}
		},
	};
</script>
<style lang="less" scoped>
	.from-item-width {
		width: 140px;
	}

	.qrcode {
		.device-name {
			text-align: center;
		}

		.qrcode-url {
			width: 250px;
			height: 250px;
			margin: 0 auto;

			img {
				width: 100%;
			}
		}
	}

	// .sd_use {
	// }
	.sd_spare {
		color: #008e4d;
	}

	.sd_spare2 {
		color: #0055ff;
		margin-left: 10px;
	}

	.sd_error {
		color: #f56c6c;
		margin-left: 10px;
	}

	.sd_total {
		color: #b28e2f;
		margin-left: 10px;
	}

	.qr-code {
		display: flex;

		.qr-code-box {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 530px;
			height: 300px;

			.qrcode-content {
				height: 300px;
			}
		}
	}
</style>
<style lang="scss">
	.logDialog {
		.el-dialog__body {
			height: calc(100% - 114px);
		}
	}
</style>
